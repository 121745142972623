import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import HeaderSEO from '../components/partials/HeaderSEO'

import Layout from '../components/Layout'
import ContactUsPage from '../hanzo/pages/contact-us'

const contactUs: React.FC = () => {
    const data = useStaticQuery(graphql`
        query ContactFormSEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Form_ContactUs" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout onlyLogo={true}>
            <HeaderSEO pageSEO={pageSEO} />
            <Helmet>
                <link rel='canonical' href='https://lenses.io/contact-us/' />
            </Helmet>
            <section>
                <ContactUsPage />
            </section>
        </Layout>
    )
}

export default contactUs
