import { generateOptions } from '../../lib/utils/forms'
import {
    PATTERN_EMAIL,
    PATTERN_NO_NUMERIC_STRING,
    PATTERN_STRING,
} from '../../lib/utils/forms/patterns'

// CONTACT US FORM
export const contactUsFormFields = [
    {
        name: 'First Name',
        label: 'First Name',
        type: 'input' as const,
        validation: generateOptions(false, PATTERN_NO_NUMERIC_STRING, 30),
    },
    {
        name: 'Last Name',
        label: 'Last Name',
        type: 'input' as const,
        validation: generateOptions(false, PATTERN_NO_NUMERIC_STRING, 30),
    },
    {
        name: 'Email',
        label: 'Email',
        type: 'input' as const,
        validation: generateOptions(false, PATTERN_EMAIL, 30),
    },
    {
        name: 'Company',
        label: 'Company',
        type: 'input' as const,
        validation: generateOptions(true, PATTERN_STRING, 30),
    },
    {
        name: 'Contact Message',
        label: 'How can we help?',
        type: 'textarea' as const,
    },
]

export const contactUsFormPardotHandler = 'https://go.pardot.com/l/877242/2020-08-04/4fkwl9'

export const contactUsFormTextBlock = [
    {
        pretitle: 'Contact us',
        title: 'Let’s talk',
        description: `
            <div>
                <p>If you’ve arrived here, it’s likely because:</p>
                <p>• You have a question</p>
                <p>• You’re interested in working with us</p>
                <p>• You’re ready to buy Lenses (request a quote <a href='/request-quote'>here</a>)</p>
                <p>To email us directly about something else: </p>
                <p>Contact Sales at <a href='mailto:sales@lenses.io'>sales@lenses.io</a></p>
                <p>Contact Support at <a href='mailto:support@lenses.io'>support@lenses.io</a></p>
            </div>`,
    },
]

export const contactUsFormSubmitButtonLabel = 'Send'
export const contactUsFormSuccessTitle = 'Thanks for reaching out'
export const contactUsFormSuccessDescription = 'We’ll get back to you soon.'
export const formErrorTitle = 'There has been an error'
export const formErrorDescription =
    'There has been an error when sending your request. Please try again.'
export const formErrorButtonLabel = 'Try again'
export const formErrorButtonLink = '/contact-us'
