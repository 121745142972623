import React, { useEffect, useState } from 'react'
import * as myStyles from './styles.module.scss'
import { useForm } from 'react-hook-form'
import DynamicForm from '../../lib/components/components/dynamic-form'
import FlexibleModule from '../../lib/components/modules/flexible'
import { FormState } from '../../lib/components/components/dynamic-form/types'
import {
    contactUsFormTextBlock,
    contactUsFormFields,
    contactUsFormPardotHandler,
    contactUsFormSubmitButtonLabel,
    contactUsFormSuccessTitle,
    formErrorTitle,
    contactUsFormSuccessDescription,
    formErrorDescription,
    formErrorButtonLabel,
    formErrorButtonLink,
} from './constants'

const ContactUsPage = () => {
    const styles: any = myStyles

    const [contactUsFormStatus, setContactUsFormStatus] = useState<FormState>('empty')

    const contactUsForm = useForm<any>({
        mode: 'onBlur',
    })

    useEffect(() => {
        const url = new URL(window.location.href)
        const contactUsStatus = url.searchParams.get('status')
        if (contactUsStatus === 'success') setContactUsFormStatus('success')
        if (contactUsStatus === 'error') setContactUsFormStatus('error')
    }, [])

    return (
        <div className={styles.contact_us_page}>
            <div className={styles.content}>
                <FlexibleModule textBlocks={contactUsFormTextBlock} hasBorder={false}>
                    <DynamicForm
                        status={contactUsFormStatus}
                        fields={contactUsFormFields}
                        form={contactUsForm}
                        action={contactUsFormPardotHandler}
                        submitButtonLabel={contactUsFormSubmitButtonLabel}
                        responseMessage={{
                            title:
                                contactUsFormStatus === 'success'
                                    ? contactUsFormSuccessTitle
                                    : formErrorTitle,
                            description:
                                contactUsFormStatus === 'success'
                                    ? contactUsFormSuccessDescription
                                    : formErrorDescription,
                            buttonLabel:
                                contactUsFormStatus === 'error' ? formErrorButtonLabel : null,
                            buttonLink:
                                contactUsFormStatus === 'error' ? formErrorButtonLink : null,
                        }}
                    />
                </FlexibleModule>
            </div>
        </div>
    )
}

export default ContactUsPage
